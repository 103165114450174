"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Soundcloud = void 0;
var API_1 = require("./API");
var entities_1 = require("./entities");
/**
 * The main class for interacting with the Soundcloud API.
 */
var Soundcloud = /** @class */ (function () {
    function Soundcloud(clientId, oauthToken, options) {
        this.apps = new entities_1.Apps(this);
        this.comments = new entities_1.Comments(this);
        this.me = new entities_1.Me(this);
        this.oembed = new entities_1.Oembed(this);
        this.playlists = new entities_1.Playlists(this);
        this.resolve = new entities_1.Resolve(this);
        this.tracks = new entities_1.Tracks(this);
        this.users = new entities_1.Users(this);
        this.util = new entities_1.Util(this);
        var opts = {};
        if (typeof clientId === "string") {
            // eslint-disable-next-line no-console
            console.warn("`Soundcloud(clientId, oauthToken, options)` is deprecated. Use `Soundcloud({ clientId, oauthToken, proxy })` instead.");
            opts.clientId = clientId;
            opts.oauthToken = oauthToken;
            opts.proxy = options === null || options === void 0 ? void 0 : options.proxy;
        }
        else {
            Object.assign(opts, clientId);
        }
        if (opts.clientId) {
            Soundcloud.clientId = opts.clientId;
            if (opts.oauthToken)
                Soundcloud.oauthToken = opts.oauthToken;
        }
        if (opts.proxy)
            Soundcloud.proxy = opts.proxy;
        this.api = new API_1.API(Soundcloud.clientId, Soundcloud.oauthToken, Soundcloud.proxy);
    }
    return Soundcloud;
}());
exports.Soundcloud = Soundcloud;
__exportStar(require("./entities"), exports);
__exportStar(require("./types"), exports);
__exportStar(require("./API"), exports);
exports.default = Soundcloud;
module.exports.default = Soundcloud;
